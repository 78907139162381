// General imports
import React from "react";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import './CarouselReact.css';

// component imports
import ReactPlayer from "react-player";

const CarouselReact = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }

    return (
        <Carousel responsive={responsive}>
            <div className="playerDiv">
                <ReactPlayer url='https://www.youtube.com/watch?v=MFwRBad4ZCw'
                    className='reactPlayerHomepage'
                    playing={true}
                    muted={true}
                    height="100%"
                    width="100%">
                </ReactPlayer>
            </div>
            <div className="playerDiv">
                <ReactPlayer url='https://www.youtube.com/watch?v=ngNhVvIJrjI'
                    className='reactPlayerHomepage'
                    playing={true}
                    muted={true}
                    height="100%"
                    width="100%">
                </ReactPlayer>
            </div>
        </Carousel>
    )
}

export default CarouselReact;