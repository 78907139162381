// General imports
import React from "react";
import './About.css';

// Image Imports
import ContentOne from "./img/ContentImgOne.png";
import ContentTwo from "./img/ContentImgTwo.png";

const About = (props) => {

    return (

        <>

            <div id="AboutDiv">
                <div id="AboutHeader">
                    <h1>About Us</h1>
                </div>
                <div className="AboutContent">
                    <img src={ContentOne} alt="ContentImgOne" id="ContentPicOne" />
                    <p className="AboutText">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum facilis fugit architecto soluta sunt dicta laborum, et sed saepe consequatur facere, perspiciatis tenetur dolor deleniti nam aspernatur ab nemo dolorem.Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum facilis fugit architecto soluta sunt dicta laborum, et sed saepe consequatur facere, perspiciatis tenetur dolor deleniti nam aspernatur ab nemo.</p>
                </div>
                <div className="AboutContent" id="ContentTwo">
                    <p className="AboutText">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum facilis fugit architecto soluta sunt dicta laborum, et sed saepe consequatur facere, perspiciatis tenetur dolor deleniti nam aspernatur ab nemo dolorem.Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum facilis fugit architecto soluta sunt dicta laborum, et sed saepe consequatur facere, perspiciatis tenetur dolor deleniti nam aspernatur ab nemo.</p>
                    <img src={ContentTwo} alt="ContentImgTwo" id="ContentPicTwo" />
                </div>
            </div>

        </>

    )
}

export default About;