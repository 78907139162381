// General imports
import React from "react";
import ReactPlayer from "react-player";
import './Home.css';

// Component Imports
import CarouselReact from './CarouselReact';

// image imports
import logoHomepage from './img/CRLLogoBW.png';
import navbarLogoHomepage from './img/CRLLogoBW.png';

const Home = (props) => {

    // Delay for fadeOut animation
    function fadeOutDelay() {
        setTimeout(fadeOutLogo, 3000);
    }

    // fadeOut animation for logo, subheader (& remove overlay)
    function fadeOutLogo() {
        var fadeTarget = document.getElementById("riverwalkLogoHomepage");
        var fadeTargetTwo = document.getElementById("homepageSubheader");
        var fadeTargetRemove = document.getElementById("homePageOverlay");
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
                fadeTargetTwo.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
                fadeTargetTwo.style.opacity -= 0.1;
            } else {
                fadeTargetRemove.remove();
                clearInterval(fadeEffect);
            }
        }, 150);
    }

    return (
        <>
            {/* homepage livestreams (with logo, subheader & overlay) */}
            <div id="homePageDiv">
                <CarouselReact />
                <div id="homepageNavContainer">
                    <img src={navbarLogoHomepage} alt="Chicago Riverwalk Live Logo" id="navbarLogoHomepage" />
                    <p id="riverwalkTextHomepage">chicagoriverwalklive.com</p>
                </div>
                <div id="homePageOverlay" onLoad={fadeOutDelay}></div>
                <img src={logoHomepage} alt="Chicago Riverwalk Live Logo" id="riverwalkLogoHomepage" onLoad={fadeOutDelay} />
                <div id="homepageSubheader" onLoad={fadeOutDelay}>
                    <p>chicagoriverwalklive.com</p>
                </div>
            </div>
            <div id="homePageDivMobile">
                <div className="mobilePlayerDiv">
                    <ReactPlayer url='https://www.youtube.com/watch?v=MFwRBad4ZCw'
                        className='reactPlayerHomepageMobile'
                        playing={true}
                        muted={true}
                        height="100%"
                        width="100%">
                    </ReactPlayer>
                    <div className="homepageNavContainerMobile">
                        <img src={logoHomepage} alt="Chicago Riverwalk Live Logo" className="mobileLogo"/>
                        <p className="riverwalkTextHomepageMobile">chicagoriverwalklive.com</p>
                    </div>
                </div>
                <div className="mobilePlayerDiv">
                    <ReactPlayer url='https://www.youtube.com/watch?v=ngNhVvIJrjI'
                        className='reactPlayerHomepageMobile'
                        playing={true}
                        muted={true}
                        height="100%"
                        width="100%"
                        style={{marginBottom:"10rem"}}>
                    </ReactPlayer>
                    <div className="homepageNavContainerMobile">
                        <img src={logoHomepage} alt="Chicago Riverwalk Live Logo" className="mobileLogo"/>
                        <p className="riverwalkTextHomepageMobile">chicagoriverwalklive.com</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;