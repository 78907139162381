// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from 'hamburger-react';
import './Navbar.css';

// component imports
import Menu from "./Menu";

// image imports
import navbarLogo from './img/CRLLogoBW.png';

const Navbar = (props) => {

    // state variables
    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const [colorChange, setColorChange] = useState(false);

    // function and event listener for navbar color change on scroll
    const changeNavbarColor = () => {
        if(window.scrollY >= 125) {
            setColorChange(true);
        } else {
            setColorChange(false);
        }
    };

    window.addEventListener("scroll", changeNavbarColor);

    return (

        <>

            {/* Hamburger Menu - Only visible on mobile */}

            <div className="HamburgerMenuDiv" style={{ display: hamburgerOpen ? 'inline' : 'none' }}>
                <Menu hamburgerstatus={hamburgerOpen} sethamburgerstatus={setHamburgerOpen}/>
            </div>

            {/* Navbar */}

            <nav>
                <div className={colorChange ? 'navbar colorChange' : 'navbar'}>
                    <img src={navbarLogo} alt="Chicago Riverwalk Live Logo" id="navbarLogo"/>
                    <div className="navLinks">
                        <ul className="links">
                            <li><Link to="/" className="navigationLink">Home</Link></li>
                            <li><Link to="/map" className="navigationLink">Map</Link></li>
                            {/* <li><Link to="/about" className="navigationLink">About</Link></li> */}
                            <li><Link to="/contact" className="navigationLink">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="hamburgerContainer">
                        <Hamburger color="#FFFFFF" duration={0.7} rounded onToggle={toggled => {
                            if (toggled) {
                                setHamburgerOpen(!hamburgerOpen)
                            } else {
                                setHamburgerOpen(!hamburgerOpen)
                            }
                        }} />
                    </div>
                </div>
            </nav>

        </>

    )
}

export default Navbar;