// General imports
import React, { useState, useRef, useEffect } from "react";
import './Map.css';
// GeoJSON imports
import geoJsonFood from './Geojsondata/featuresFood.json';
import geoJsonDrinks from './Geojsondata/featuresDrinks.json';
import geoJsonEntertainment from './Geojsondata/featuresEntertainment.json';
import geoJsonBoats from './Geojsondata/featuresBoats.json';

// Image Imports
import mapBackground from './img/riverwalkMapBackground.png';
import mobileBackground from './img/riverwalkBridge.png';

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoiYWRpeG9uODciLCJhIjoiY2w1OGxhdDEwMDB0NTNqbzZzNTNmNGttbiJ9.Z1BbEPG038ErM3hls7JROQ'

const Map = (props) => {

    const mapContainerRef = useRef(null);
    const [showFood, setShowFood] = useState(false);
    const [showDrinks, setShowDrinks] = useState(false);
    const [showEntertainment, setShowEntertainment] = useState(false);
    const [showExercise, setShowExercise] = useState(false);
    const [showBoats, setShowBoats] = useState(false);
    const [geoJson, setGeoJson] = useState(geoJsonFood);

    useEffect(() => {
        updateMap();
    }, [geoJson]);

    function updateMap() {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [-87.62, 41.888],
            zoom: 16,
        });

        geoJson.features.map((feature) =>
            new mapboxgl.Marker()
                .setLngLat(feature.geometry.coordinates)
                .setPopup(new mapboxgl.Popup({ offset: 25 })
                    .setHTML(feature.properties.title))
                .addTo(map)
        );

        map.addControl(new mapboxgl.NavigationControl(), "top-right");

        return () => map.remove();
    }

    // Following are functions used to display/hide item lists on button clicks
    function displayFood(e) {
        e.preventDefault();
        setShowFood(current => !current);
        setShowDrinks(false);
        setShowEntertainment(false);
        setShowExercise(false);
        setShowBoats(false);
        setGeoJson(geoJsonFood);
    }

    function displayDrinks(e) {
        e.preventDefault();
        setShowDrinks(current => !current);
        setShowFood(false);
        setShowEntertainment(false);
        setShowExercise(false);
        setShowBoats(false);
        setGeoJson(geoJsonDrinks);
    }

    function displayEntertainment(e) {
        e.preventDefault();
        setShowEntertainment(current => !current);
        setShowDrinks(false);
        setShowFood(false);
        setShowExercise(false);
        setShowBoats(false);
        setGeoJson(geoJsonEntertainment);
    }

    function displayExercise(e) {
        e.preventDefault();
        setShowDrinks(current => !current);
        setShowFood(false);
        setShowEntertainment(false);
        setShowExercise(false);
        setShowBoats(false);
        setGeoJson(geoJsonDrinks);
    }

    function displayBoats(e) {
        e.preventDefault();
        setShowBoats(current => !current);
        setShowDrinks(false);
        setShowEntertainment(false);
        setShowExercise(false);
        setShowFood(false);
        setGeoJson(geoJsonBoats);
    }

    return (
        <>

            {/* Map Page Container */}

            <div id="MapDiv">
                <img src={mapBackground} alt="city background" id="mapBackground" />
                <img src={mobileBackground} alt="city background" id="mapBackgroundMobile" />

                {/* Riverwalk Container */}

                <div id="RiverwalkDiv">
                    <h2 id="RiverwalkHeader">Explore</h2>
                    <br></br>

                    {/* Mapbox Container */}

                    <div id="mapBoxContainer">

                        {/* Desktop Location Display */}

                        <div id="desktopLocationContainer">
                            {showFood && (
                                <div className="locationDisplay">
                                    <h3>Dining:</h3>
                                    <ul>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/beat-kitchen-on-the-riverwalk-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Beat Kitchen on the Riverwalk</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/brown-bag-seafood-co-seafood-chicago-il" rel='noreferrer' target="_blank">Brown Bag Seafood Co.</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/catch-35-seafood-chicago-il" rel='noreferrer' target="_blank">Catch 35</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/chicago-cut-steakhouse-steak-house-chicago-il" rel='noreferrer' target="_blank">Chicago Cut Steak House</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/gibsons-italia-fine-dining-chicago-il" rel='noreferrer' target="_blank">Gibson's Italia</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/house-of-blues-chicago-american-casual-chicago-il" rel='noreferrer' target="_blank">House of Blues Chicago</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/mccormick-schmick-s-seafood-steaks-seafood-chicago-il" rel='noreferrer' target="_blank">McCormick & Schmick's Seafood & Steaks</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/morton-s-the-steakhouse-steak-house-chicago-il" rel='noreferrer' target="_blank">Morton's The Steakhouse</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/morton-s-the-steakhouse-steak-house-chicago-il" rel='noreferrer' target="_blank">Morton's The Steakhouse</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/napoli-s-italian-restaurant-restaurants-cafe-decatur-il" rel='noreferrer' target="_blank">Napoli's Italian Restaurant</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/o-briens-riverwalk-cafe-american-casual-chicago-il" rel='noreferrer' target="_blank">Obrien's Riverwalk Cafe</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/smith-wollensky-seafood-chicago-il" rel='noreferrer' target="_blank">Smith & Wollensky</a></li>
                                    </ul>
                                </div>
                            )}

                            {showDrinks && (
                                <div className="locationDisplay">
                                    <h3>Drinks:</h3>
                                    <ul>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/beat-kitchen-on-the-riverwalk-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Beat Kitchen on the Riverwalk</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/riverwalk-wine-garden-by-city-winery-winery-chicago-il" rel='noreferrer' target="_blank">Riverwalk Wine Garden By City Winery</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/tiny-tapp-cafe-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Tiny Tapp & Cafe</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/island-party-hut-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Island Party Hut</a></li>
                                    </ul>
                                </div>
                            )}

                            {showEntertainment && (
                                <div className="locationDisplay">
                                    <h3>Entertainment and Attractions:</h3>
                                    <ul>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/chicago-water-taxi-riverwalk-taxi-boat-chicago-il" rel='noreferrer' target="_blank">Chicago Water Taxi</a></li>
                                        <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/island-party-boat-party-boat-chicago-il" rel='noreferrer' target="_blank">Island Party Boat</a></li>
                                    </ul>
                                </div>
                            )}

                            {showExercise && (
                                <div className="locationDisplay">
                                    <h3>Shopping:</h3>
                                    <ul>
                                        <li className="listItem">Galleria and Tower</li>
                                        <li className="listItem">North loop Terrace</li>
                                        <li className="listItem">Merchandise Mart</li>
                                        <li className="listItem">The Shops at North Bridge</li>
                                    </ul>
                                </div>
                            )}

                            {showBoats && (
                                <div className="locationDisplay">
                                    <h3>Hotels:</h3>
                                    <ul>
                                        <li className="listItem">Sheraton Grand Chicago</li>
                                        <li className="listItem">Radisson Blue Aqua Hotel</li>
                                        <li className="listItem">London House Chicago</li>
                                        <li className="listItem">The Langham Chicago</li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* Mapbox div */}

                        <div ref={mapContainerRef} className="map-container" />
                    </div>

                    {/* Button Containers (for desktop and mobile versions of site) */}

                    <div id="MapButtonContainer">
                        <button className="MapButton" onClick={displayFood}>Eat</button>
                        <button className="MapButton MiddleButtons" onClick={displayDrinks}>Drink</button>
                        <button className="MapButton" onClick={displayEntertainment}>Play</button>
                        <button className="MapButton MiddleButtons" onClick={displayExercise}>Shop</button>
                        <button className="MapButton" onClick={displayBoats}>Stay</button>
                    </div>
                    <div id="MobileButtonContainer">
                        <label htmlFor="selectMapFilter" id="selectMapFilterLabel">Select Map Filter:</label>
                        <select name="selectMapFilter" id="selectMapFilter" defaultValue="eat">
                            <option value="eat" onChange={displayFood}>Eat</option>
                            <option value="drink" onChange={displayDrinks}>Drink</option>
                            <option value="play" onChange={displayEntertainment}>Play</option>
                            <option value="shop" onChange={displayExercise}>Shop</option>
                            <option value="stay" onChange={displayBoats}>Stay</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Mobile location display */}

            <div id="mobileLocationContainer">
                {showFood && (
                    <div className="mobileLocationDisplay">
                        <h3>Dining:</h3>
                        <ul>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/beat-kitchen-on-the-riverwalk-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Beat Kitchen on the Riverwalk</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/brown-bag-seafood-co-seafood-chicago-il" rel='noreferrer' target="_blank">Brown Bag Seafood Co.</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/catch-35-seafood-chicago-il" rel='noreferrer' target="_blank">Catch 35</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/chicago-cut-steakhouse-steak-house-chicago-il" rel='noreferrer' target="_blank">Chicago Cut Steak House</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/gibsons-italia-fine-dining-chicago-il" rel='noreferrer' target="_blank">Gibson's Italia</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/house-of-blues-chicago-american-casual-chicago-il" rel='noreferrer' target="_blank">House of Blues Chicago</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/mccormick-schmick-s-seafood-steaks-seafood-chicago-il" rel='noreferrer' target="_blank">McCormick & Schmick's Seafood & Steaks</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/morton-s-the-steakhouse-steak-house-chicago-il" rel='noreferrer' target="_blank">Morton's The Steakhouse</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/morton-s-the-steakhouse-steak-house-chicago-il" rel='noreferrer' target="_blank">Morton's The Steakhouse</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/napoli-s-italian-restaurant-restaurants-cafe-decatur-il" rel='noreferrer' target="_blank">Napoli's Italian Restaurant</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/o-briens-riverwalk-cafe-american-casual-chicago-il" rel='noreferrer' target="_blank">Obrien's Riverwalk Cafe</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/smith-wollensky-seafood-chicago-il" rel='noreferrer' target="_blank">Smith & Wollensky</a></li>
                        </ul>
                    </div>
                )}

                {showDrinks && (
                    <div className="mobileLocationDisplay">
                        <h3>Drinks:</h3>
                        <ul>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/beat-kitchen-on-the-riverwalk-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Beat Kitchen on the Riverwalk</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/riverwalk-wine-garden-by-city-winery-winery-chicago-il" rel='noreferrer' target="_blank">Riverwalk Wine Garden By City Winery</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/tiny-tapp-cafe-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Tiny Tapp & Cafe</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/island-party-hut-bar-beer-and-drinks-chicago-il" rel='noreferrer' target="_blank">Island Party Hut</a></li>
                        </ul>
                    </div>
                )}

                {showEntertainment && (
                    <div className="mobileLocationDisplay">
                        <h3>Entertainment and Attractions:</h3>
                        <ul>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/chicago-water-taxi-riverwalk-taxi-boat-chicago-il" rel='noreferrer' target="_blank">Chicago Water Taxi</a></li>
                            <li className="listItem"><a href="https://2f1fbc.idealdirectories.com/listings/island-party-boat-party-boat-chicago-il" rel='noreferrer' target="_blank">Island Party Boat</a></li>
                        </ul>
                    </div>
                )}

                {showExercise && (
                    <div className="mobileLocationDisplay">
                        <h3>Shopping:</h3>
                        <ul>
                            <li className="listItem">Galleria and Tower</li>
                            <li className="listItem">North loop Terrace</li>
                            <li className="listItem">Merchandise Mart</li>
                            <li className="listItem">The Shops at North Bridge</li>
                        </ul>
                    </div>
                )}

                {showBoats && (
                    <div className="mobileLocationDisplay">
                        <h3>Hotels:</h3>
                        <ul>
                            <li className="listItem">Sheraton Grand Chicago</li>
                            <li className="listItem">Radisson Blue Aqua Hotel</li>
                            <li className="listItem">London House Chicago</li>
                            <li className="listItem">The Langham Chicago</li>
                        </ul>
                    </div>
                )}
            </div>

        </>
    )
}

export default Map;